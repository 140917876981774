import { checkStatus } from '../utilities/fetch';

$(document).ready(function () {
  var LOGIN_PATH = '/login';

  /**
   * Prevent the browser from automatically logging the user back in
   *
   * @returns {undefined}
   */
  var preventAutoLogin = function () {
    var currentDate = new Date();
    localStorage.setItem('preventAutoLogin', currentDate.getTime().toString());
    if (window.PasswordCredential) {
      navigator.credentials.preventSilentAccess();
    }
  };

  /**
   * Checks if the current user can use the auto-login functionality
   *
   * @returns {boolean} true if user can use the autoLogin
   */
  var canAutoLogin = function () {
    // Check if the API is available
    if (!window.PasswordCredential) {
      return false;
    }

    // Check if the `preventAutoLogin` flag has been set
    var preventAutoLoginExist = localStorage.getItem('preventAutoLogin');
    if (!preventAutoLoginExist) {
      return true;
    }

    // If it has been set, check if it's older than 8 hours. If so, return `true`.
    // Eight hours were chosen to reflect a user who logs out at the end of a working
    // day. This way, the next day the user will be signed in again.
    var logoutDate = new Date(parseInt(preventAutoLoginExist));
    return logoutDate < new Date().getTime() - 8 * 60 * 60 * 1000;
  };

  /**
   * Use the `navigation.credentials` API to automatically log the user in (if API is available).
   * After a successful login, it reloads the page. If the login was not successful, the auto-login
   * feature will be deactivated until the next successful manual login.
   *
   * @returns {undefined}
   */
  var autoLogin = function () {
    if (!canAutoLogin()) {
      return;
    }

    navigator.credentials
      .get({
        password: true,
        mediation: 'optional',
      })
      .then(function (cred) {
        if (cred) {
          const authToken = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content');

          const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': authToken,
            },
            body: JSON.stringify({
              login: cred.id,
              password: cred.password,
            }),
          };

          fetch(LOGIN_PATH, options)
            .then(checkStatus)
            .then(() => {
              location.reload();
            })
            .catch(() => {
              // After an unsuccessful auto-login, don't try to auto-login again
              preventAutoLogin();
              // In order to test the function, check if we are in develop
              if (window.location.href.indexOf('develop') !== -1) {
                // If the auto-login fails, redirect to login and shows an error message.
                window.location.replace(LOGIN_PATH + '?invalid=1');
              }
            });
        } else {
          preventAutoLogin();
        }
      });
  };
  autoLogin();
});
